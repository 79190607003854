.container {
    max-width: inherit;
    margin-top: 20px;
  }
  
  .category {
    margin-bottom: 20px;
    max-width: inherit;
  }
  
  .category-info {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  
  .category-info h2 {
    margin-right: 10px;
    font-weight: bold;
  }
  
  .category-info p {
    margin-right: 10px;
  }
  
  .grade-icon {
    width: 20px;
    height: 20px;
  }
  
  .A {
    background-color: #54e254;
  }
  
  .B {
    background-color: #54e254;
  }
  
  .C {
    background-color: orange;
  }
  
  .D {
    background-color: orange;
  }
  
  .E {
    background-color: red;
    color: white;
  }
  
  .F {
    background-color: darkred;
    color: white;
  }
  
  .checklist-item {
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }
  
  .checklist-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    background-color: #f7f7f7;
  }
  
  .rule-details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .label {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .value {
    margin-right: 10px;
  }
  
  .score {
    margin-right: 10px;
    text-align: right;
  }
  
  .icon {
    font-size: 20px;
  }
  
  .cross {
    color: red;
  }
  
  .check {
    color: green;
  }
  
  .details {
    padding: 10px;
    background-color: #f7f7f7;
  }
  
  .message {
    margin-bottom: 10px;
  }
  
  .link {
    color: blue;
  }
  