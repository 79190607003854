body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .container {
    max-width: inherit;
    margin: 0 auto;
    padding: 20px;
  }
  
  
  .input-field,
  .button {
    height: 50px;
    box-sizing: border-box;
    color: black;
    border: 3px solid #ccc;
    border-radius: 6px;
  }
  
  .input-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

  }
  
  .input-field {
    flex: 1;
    height: 50px;
    padding: 10px;
    border: 3px solid #ccc;
    border-radius: 6px;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
  }
  
  .button {
    margin-left: 10px;
    padding: 0 20px;
    background-color: #4285F4;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .loader-text {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .loader-logo {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
  
  .progress-bar-container {
    width: 80%;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #4285f4;
    width: 0;
    transition: width 1s ease;
  }
  
  
  @keyframes progress {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
  
  result-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
  }
  
  .result-table th,
  .result-table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .result-table th {
    background-color: #f2f2f2;
  }
  
  .result-details {
    display: none;
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  .accordion {
    cursor: pointer;
  }
  
  
  .accordion.active {
    background-color: #f7f7f7;
  }
  
  .accordion-content {
    display: none;
  }
  
  .accordion-content.active {
    display: block;
  }